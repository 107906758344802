import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paragraph as LuxParagraph } from '@lux/components/src/components/baseElements';
import { Theme } from '@lux/components';

const StyledParagraph = styled(LuxParagraph)`
  ${Theme.media.sm`
    margin: 4px 0;
    font-size: ${Theme.fonts.sizes.small};
  `} ${Theme.media.md`
    margin: 8px 0;
    font-size: ${Theme.fonts.sizes.regular};
  `};
`;

const Paragraph = (props) => (
  <StyledParagraph {...props}>{props.children}</StyledParagraph>
);

Paragraph.propTypes = {
  children: PropTypes.node
};

export default Paragraph;
