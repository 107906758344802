import styled, { keyframes } from 'styled-components';

const scrolllingIn = keyframes`
  0% {  transform: translateY(100px); }
  100% { transform:translateY(0px);  }
`;

const scrolllingOut = keyframes`
  0% {  transform:translateY(0px); }
  100% {transform: translateY(105px);  }
`;

const fadeIn = keyframes`
  0% { opacity:0; }
  100% { opacity:1; }
`;

export default {
  Container: styled.div`
    height: 100px;
    padding: 10px;
    width: 100%;
  `,
  Content: styled.div`
    height: 90px;
    overflow: hidden;
    position: relative;
    animation: ${fadeIn} 6s;
    user-select: none;
  `,
  Label: styled.div`
    display: flex;
    position: absolute;
    transform: ${(props) =>
      props.currentIndex === props.index
        ? 'translateY(0)'
        : 'translateY(105px)'};
    width: 100%;
    height: 100%;
    animation: ${(props) =>
        props.currentIndex === props.index ? scrolllingIn : scrolllingOut}
      2s;
  `,
  LabelQR: styled.div`
    position: absolute;
    transform: ${(props) =>
      props.currentIndex === props.index
        ? 'translateY(0)'
        : 'translateY(105px)'};
    width: 100%;
    height: 100%;
    animation: ${(props) =>
        props.currentIndex === props.index ? scrolllingIn : scrolllingOut}
      2s;
  `,

  LabelWrapper: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
  `,
  TimeLabel: styled.p`
    margin-right: 10px;
  `,
  ContainerQR: styled.div`
    height: 145px;
    padding: 10px;
    width: 100%;
  `
};
