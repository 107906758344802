import styled from 'styled-components';
import Theme from '@/theme';
import { Anchor } from '@lux/components';

export default {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  `,
  Frame: styled.iframe`
    width: 100%;
    height: 300px;
    margin: 10px 0;
  `,
  BannerContainer: styled.a`
    width: 100%;
    height: 100%;
  `,
  LabelRed: styled.strong`
    color: ${Theme.colours.singtelred};
  `,
  List: styled.div`
    padding: 10px;
  `,
  ListItem: styled.div`
    padding-left: 25px;
    padding-bottom: 10px;
  `,
  ListTitle: styled.div`
    padding-bottom: 10px;
    font-size: 15px;
  `,
  ListNumber: styled.span`
    padding-right: 10px;
  `,
  LinkAnchor: styled(Anchor)`
    color: ${Theme.colours.singtelred};
  `,
  ListAlpha: styled.li`
    list-style-type: lower-alpha;
  `,
  ListAlpha1: styled.li`
    list-style-type: lower-alpha;
    padding-left: 25px;
  `,
  ListAlpha2: styled.li`
    list-style-type: lower-alpha;
    padding-left: 27px;
  `,
  ListUl: styled.ol`
    padding-left: 25px;
  `,
  List1: styled.div`
    padding: 5px;
  `,
  List2: styled.div`
    padding: 7px;
  `
};
