import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Anchor, Row, Column, Label } from '@lux/components';
import { Modal } from '@/components/Lux';
import Title from '@/components/Title';
import moment from 'moment';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';
import { formatTextNewLine, isProduction } from '@/utils/helpers';
import settings from 'static/assets/config/settings.json';
import { DATE_FORMAT } from '@/utils/constants';
import * as actions from '@/actions/LoginActions';
import SC from './styles';

const getHost = () => {
  if (isProduction()) {
    // const hostNameList = window.location.host.split('.');
    const hostNameList =
      typeof window !== 'undefined' ? window.location.host.split('.') : [];
    return hostNameList.reduce(
      (total, currentValue, index) =>
        index > 0 ? total + (total ? '.' : '') + currentValue : total,
      ''
    );
  } else {
    return settings.appHost;
  }
};
const hostName = getHost();
const selector = ({ login }) => ({
  notifications: login.notifications
});

const NotificationBar = (props) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(selector);
  const latestContentLength = useRef();
  //current index of notification
  const [currentIndex, setCurrentIndex] = useState(0);
  //open status of modal
  const [open, setOpenModal] = useState(false);
  //current notification item that user selected
  const [currentItem, setCurrentItem] = useState({});
  const { location = 'login', interval = 8000 } = props;
  const timer = useRef();
  useEffect(() => {
    dispatch(actions.getNotifications.request(location));
  }, [dispatch, location]);

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setCurrentIndex((prev) =>
        prev === latestContentLength.current - 1 ? 0 : prev + 1
      );
    }, interval);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (notifications) {
      latestContentLength.current = notifications.length;
    }
  }, [notifications, location]);

  return (
    <SC.Container>
      {notifications && notifications.length > 0 ? (
        <>
          <SC.Content>
            {notifications.map((item, i) => (
              <>
                {item.imageUrl ? (
                  <SC.LabelQR
                    key={item.createDate}
                    index={i}
                    currentIndex={currentIndex}
                  >
                    <Row>
                      <Column sm={12} md={1} lg={1}>
                        <img
                          alt="img placeholder"
                          src={`https://web.${hostName}${item.imageUrl}`}
                          width="100px"
                          height="auto"
                        />
                      </Column>
                      <Column sm={12} md={11} lg={11}>
                        <SC.TimeLabel>
                          {moment(item.postDateFrom).format(DATE_FORMAT)}
                        </SC.TimeLabel>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatTextNewLine(item.message)
                          }}
                        />
                        <Anchor
                          secondary
                          target="_blank"
                          href={item?.link && `${item?.link}`}
                        >
                          {item?.link && `${item?.link}`}
                        </Anchor>
                      </Column>
                    </Row>
                  </SC.LabelQR>
                ) : (
                  <SC.Label
                    key={item.createDate}
                    index={i}
                    currentIndex={currentIndex}
                  >
                    <SC.TimeLabel>
                      {moment(item.postDateFrom).format(DATE_FORMAT)}
                    </SC.TimeLabel>
                    <Anchor
                      secondary
                      onClick={() => {
                        setCurrentItem(item);
                        setOpenModal(true);
                      }}
                    >
                      {item.message}
                    </Anchor>
                  </SC.Label>
                )}
              </>
            ))}
          </SC.Content>
          <Modal
            open={open}
            onClose={() => {
              setOpenModal(false);
            }}
          >
            <Title title="Details" level={3} />
            Post Date: {moment(currentItem.postDateFrom).format(DATE_FORMAT)}
            <br />
            Message: {currentItem.message}
          </Modal>
        </>
      ) : (
        <Row>
          <Column>No Alerts or Notifications.</Column>
        </Row>
      )}
    </SC.Container>
  );
};

NotificationBar.propTypes = {
  location: PropTypes.oneOf(['landing', 'login']),
  interval: PropTypes.number
};

export default NotificationBar;
