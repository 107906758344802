import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { remCalc } from '@lux/helpers';
import React, { useEffect, useState, Fragment } from 'react';
import { Theme, Grid, Row, Column, Anchor } from '@lux/components';
import IconTickFilled from '@lux/components/src/assets/svgs/tick-filled.svg';

import * as msgs from '@/actions/SnackbarActions';
import * as actions from '@/actions/LoginActions';
import { Spinner } from '@/components/Lux';
import Content from '@/components/Content';
import Paragraph from '@/components/Paragraph';
import LoginWidget from '@/components/LoginWidget';
import Carousel from '@/components/Carousel';
import MarketingBanner from '@/components/MarketingBanner';
import getUrl from '@/utils/urlUtils';
import { SYSTEM_PATH_PREFIX } from '@/utils/constants';
import { getFlow } from '@/utils/helpers/login';
import LSC from '@/theme/customLuxHeadingStyle';
import SC from './styles';

const WidgetWrapper = styled.div`
  ${Theme.media.sm`display: none;`} ${Theme.media.lg`display: block`};
`;

const LoginWrapper = styled(Grid)`
  width: 100% !important;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #e4f3ef;
  ${({ flow }) => flow && `height: calc(100vh - 180px);`}
`;

const pointers = [
  'Bill management and cost tracking',
  'Instant visibility of Network Readiness and Performance',
  'Purchase or Upgrade services anytime, anywhere',
  'Network fault reporting and management'
];

const selector = ({ login }) => ({
  contents: login.contents,
  isLoggedIn: login.isLoggedIn,
  authStatus: login.currentUser.authStatus,
  isPending: login.isPending,
  forgotPassStatus: login.forgotPassStatus
});

// const ContentWrapper = styled.div`
//   padding: 32px;
//   display: flex;
//   border: 1px solid black;
//   height: 100%;
// `;

const Login = function ({ location }) {
  const dispatch = useDispatch();
  const { message } = location?.state || {};
  const {
    contents,
    isLoggedIn,
    authStatus,
    isPending,
    forgotPassStatus
  } = useSelector(selector);
  const [carousels, setCarousels] = useState([]);
  const [marketings, setMarketings] = useState([]);
  // Commented for now, later will resume to extend this
  // useEffect(() => {
  //   message && dispatch(msgs.danger(message));
  // }, [message]);

  const { flow, target } = getFlow();
  const isUDF = flow === 'UDF';
  const title = isUDF
    ? 'Welcome to Singtel Empower Portal'
    : 'Welcome to Singtel Business Portal';
  const subTitle = isUDF
    ? 'Login to access your services'
    : 'Login to access all your services';

  useEffect(() => {
    if (!contents.length) return;
    const setContent = async () => {
      const urls = await getUrl();
      contents.forEach((item) => {
        const results = item.contents.map((content) => ({
          imgSrc: urls.getContentImage + content.contentId,
          desc: '',
          link: content.itemLink,
          popup: content.isPopupItem,
          name: content.name,
          contentId: content.contentId
        }));
        if (item.section === 'carousel') {
          setCarousels(results);
        } else if (item.section === 'marketing') {
          setMarketings(results);
        }
      });
    };
    setContent();
  }, [contents]);

  useEffect(() => {
    dispatch(actions.getContentData.request());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (authStatus === 'LOGIN_SUCCESS') {
        if (target) {
          handleTarget();
        } else {
          navigate('/home/');
        }
      } else if (
        authStatus === 'RESET_PASSWORD' ||
        authStatus === 'PASSWORD_EXPIRED'
      ) {
        navigate('/changepassword/' + (window.location.search || ''));
      }
    }
  }, [isLoggedIn, authStatus]);

  const handleTarget = () => {
    const storedFlowData = decodeURI(sessionStorage.getItem('fT') || '');
    const { fData = [] } = JSON.parse(storedFlowData);
    const form = document.createElement('form');
    form.style.visibility = 'hidden';
    form.method = 'POST';
    form.action = `${window.location.origin}/siteminderagent/forms/login.fcc`;
    ['USER', 'PASSWORD', 'target'].forEach((fKey, fIndex) => {
      const fInput = document.createElement('input');
      fInput.name = fKey;
      fInput.value = fIndex === 1 ? fData[fIndex]?.join('') : fData[fIndex];
      form.appendChild(fInput);
    });
    document.body.appendChild(form);
    form.submit();
  };

  const onLogin = ({ userid, password }) => {
    if (target) {
      sessionStorage.setItem(
        'fT',
        encodeURI(
          JSON.stringify({
            fData: [userid, password.split(''), encodeURIComponent(target)]
          })
        )
      );
    }
    dispatch(actions.startLogin(userid.trim(), password.trim()));
  };

  return (
    <Fragment>
      <LoginWrapper flow={flow}>
        <Content>
          <Row
            style={{
              padding: '1rem 0'
            }}
          >
            {/* <Column sm={12} lg={4}>
              <LoginWidget
                onLogin={onLogin}
                title={title}
                subtitle={subTitle}
                forgotPassStatus={forgotPassStatus}
                flow={flow}
              />
            </Column> */}
            {!!isUDF ? null : (
              <Column
                // style={{
                //   padding: '60px',
                //   border: `1px solid ${Theme.colours.grey_cc}`,
                //   borderRadius: '3px',
                //   margin: 'auto'
                // }}
                sm={12}
                lg={12}
              >
                <Row
                  style={{
                    height: '100%',
                    padding: '50px 60px',
                    border: `1px solid ${Theme.colours.grey_cc}`,
                    borderRadius: '3px',
                    margin: '0 1rem'
                  }}
                  middle="sm"
                >
                  <Column sm={12} lg={12}>
                    <Row>
                      <SC.ListTitle>
                        <h4>
                          Singtel Business Portal has been discontinued with
                          effect from 28/02/2024. We have migrated your
                          BizPortal accounts to the new portals.
                        </h4>
                        <br />
                      </SC.ListTitle>
                      <SC.ListTitle />
                      <SC.ListTitle>
                        To access your
                        <strong> bills and billing reports</strong>{' '}
                        <i>
                          (View My Bill, eBill Analyser, eBill Organiser,
                          Download Bill Data)
                        </i>
                        , please log in to <strong>Empower Portal </strong>
                        at&nbsp;
                        <Anchor href="https://empower.singtel.com/">
                          empower.singtel.com
                        </Anchor>
                        &nbsp;with your BizPortal login email.
                        <SC.ListItem key="1" />
                        <SC.ListItem key="2">
                          <SC.ListNumber>•</SC.ListNumber>
                          Click <i>Access Empower now &gt; Forgot Password </i>
                          if you have forgotten your password
                        </SC.ListItem>
                        <SC.ListItem key="3">
                          <SC.ListNumber>•</SC.ListNumber>
                          If your account has not been created, click{' '}
                          <i>Create Account</i>
                        </SC.ListItem>
                      </SC.ListTitle>
                      <SC.ListTitle>
                        To access the Singtel eShop modules for purchasing
                        Singtel services:
                        <SC.ListItem key="1" />
                        <SC.ListItem key="2">
                          <SC.ListNumber>•</SC.ListNumber>
                          <strong>
                            For Singtel Business eShop, click{' '}
                            <Anchor href="https://smemobile.bizportal.singtel.com/shops/">
                              here
                            </Anchor>
                            .
                          </strong>
                        </SC.ListItem>
                        <SC.ListItem key="3">
                          <SC.ListNumber>•</SC.ListNumber>
                          <strong>
                            For Corporate Mobile eShop, click{' '}
                            <Anchor href="https://singtel.com/onlinecorpshop/">
                              here
                            </Anchor>
                            .
                          </strong>
                        </SC.ListItem>
                      </SC.ListTitle>
                    </Row>
                    <Row>
                      <SC.ListTitle>
                        For assistance, you may&nbsp;
                        <a href="/page/static/contact-us">contact us</a>.
                      </SC.ListTitle>
                    </Row>
                  </Column>
                </Row>
              </Column>
            )}
          </Row>
        </Content>
      </LoginWrapper>

      {!!isUDF ? null : (
        <WidgetWrapper>
          <Content>
            <Row>
              <Column>
                <Carousel contents={carousels} />
              </Column>
            </Row>
          </Content>
          <Content>
            <Row>
              {(marketings || []).map((item, index) => (
                <Column sm={12} lg key={index}>
                  <MarketingBanner
                    {...item}
                    showCornerAndShadow
                    theme="light"
                  />
                </Column>
              ))}
            </Row>
          </Content>
        </WidgetWrapper>
      )}
      <Spinner fullscreen show={isPending} />
    </Fragment>
  );
};

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ message: PropTypes.string })
  })
};

export default Login;
