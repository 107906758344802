import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Grid,
  Row,
  Column,
  Button as BaseButton,
  Anchor
} from '@lux/components';
import { Paragraph } from '@lux/components/src/components/baseElements';
import { remCalc } from '@lux/helpers';
import { useValidate, usePartialValidate } from '@/utils/hookUtils';
import ForgotPassword from '../ForgotPassword';
import { Modal, TextField } from '@/components/Lux';
import LabelWrapper from '@/components/LabelWrapper';

import NotificationBar from '@/components/NotificationBar';
import Theme from '@/theme';
import validationMsg from '@/utils/constants/validationMsg.json';
import LSC from '@/theme/customLuxHeadingStyle';

export const Button = styled(BaseButton)`
  ${Theme.media.sm`display: inline-block;`} ${Theme.media.md`display: block;`};
`;

const LoginCard = styled(Grid)`
  padding: ${remCalc(25)};
  background-color: ${Theme.colours.white};
  box-shadow: 0 2px 6px ${Theme.colours.grey_8a};
  border-radius: 5px;
`;

const validateList = ['userid', 'password'];

const LoginWidget = ({ onLogin, title, subtitle, forgotPassStatus, flow }) => {
  const [credentail, setCredential] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const isValid = useValidate(credentail, validateList, validationMsg.login);
  const { validation, setValidationList, onValidate } = usePartialValidate(
    credentail,
    validationMsg.login
  );
  const isUDF = flow === 'UDF';

  useEffect(() => {
    setOpenModal(forgotPassStatus);
  }, [forgotPassStatus]);

  const onInputChange = (key) => (e) => {
    const value = e.target ? e.target.value : '';
    validateList.indexOf(key) > -1 &&
      setValidationList((prev) => [...prev, key]);
    setCredential((prev) => ({ ...prev, [key]: value }));
  };

  const onLoginRequst = () => {
    onLogin(credentail);
  };

  const onKeyUp = (e) => {
    e.keyCode === 13 && onLoginRequst();
  };

  return (
    <LoginCard>
      <Row>
        <Column>
          <LSC.Heading3 level={3}>{title}</LSC.Heading3>
          <Paragraph>{subtitle}</Paragraph>
        </Column>
      </Row>

      <Row>
        <LabelWrapper
          md={12}
          lg={12}
          label="User ID"
          breakPoint={() => {}}
          hintMessage={validation.userid?.message}
        >
          <Column>
            <TextField
              id="UserID"
              name="USER"
              type="email"
              placeholder="e.g. john.doe@singtel.com"
              onChange={onInputChange('userid')}
              onKeyUp={onKeyUp}
              onBlur={onValidate}
              error={validation.userid?.isInValid}
              autoComplete={'off'}
            />
          </Column>
        </LabelWrapper>
      </Row>

      {!!isUDF ? null : (
        <Row start="sm">
          <Column>
            <Modal text="Forgot User ID">
              <LSC.Heading3 level={3}>Forget My User ID</LSC.Heading3>
              <p>
                Please contact your company administrator for retrieval of User
                ID.
              </p>
            </Modal>
          </Column>
        </Row>
      )}

      <Row>
        <LabelWrapper
          md={12}
          lg={12}
          label="Password"
          breakPoint={() => {}}
          hintMessage={validation.password?.message}
        >
          <Column>
            <TextField
              id="Password"
              name="PASSWORD"
              type="password"
              placeholder="Password"
              onChange={onInputChange('password')}
              onKeyUp={onKeyUp}
              onBlur={onValidate}
              error={validation.password?.isInValid}
              autoComplete={'off'}
            />
          </Column>
        </LabelWrapper>
      </Row>

      <Row start="sm">
        <Column>
          <Anchor
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Forgot Password
          </Anchor>
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
          >
            <ForgotPassword userid={credentail.userid} flow={flow} />
          </Modal>
        </Column>
      </Row>
      <br />
      <Row center="sm">
        <Column sm={12} md>
          <Button id="submitButton" disabled={!isValid} onClick={onLoginRequst}>
            Login
          </Button>
        </Column>
      </Row>
      <br />
      {!!isUDF ? null : (
        <Row>
          <Column sm={12}>Alerts &amp; Notifications</Column>
          <NotificationBar location="login" />
        </Row>
      )}
    </LoginCard>
  );
};

LoginWidget.propTypes = {
  onLogin: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  forgotPassStatus: PropTypes.bool,
  flow: PropTypes.string
};

export default LoginWidget;
