import styled, { css } from 'styled-components';
import { Theme } from '@lux/components';

export default {
  Background: styled.div`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    height: 290px;
    color: ${(p) =>
      p.theme === 'dark' ? Theme.colours.white : Theme.colours.black};

    ${(p) =>
      p.showCornerAndShadow &&
      css`
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 1px 0 2px 1px rgba(0, 0, 0, 0.1);
      `};
  `,
  Title: styled.h2`
    font-family: ${Theme.fonts.families.AvenirBook};
    font-size: 1.75rem;
    color: ${Theme.colours.white};
    margin: 0 auto;
    width: 90%;
    font-weight: normal;
  `,
  Description: styled.h3`
    font-family: ${Theme.fonts.families.AvenirHeavy};
    font-size: 0.75rem;
    color: ${(p) =>
      p.theme === 'dark' ? Theme.colours.white : Theme.colours.black};
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    margin: 10px auto 0;
    width: 90%;
    font-weight: normal;
  `,
  Content: styled.div`
    position: absolute;
    bottom: 0;
    width: 90%;
    margin-bottom: 20px;
  `,
  CaptionCopy: styled.div`
    font-family: ${Theme.fonts.families.AvenirBook};
    display: block;
    color: ${(p) =>
      p.theme === 'dark' ? Theme.colours.white : Theme.colours.black};
    ${(p) =>
      p.showCornerAndShadow &&
      css`
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);
      `};
  `,
  Container: styled.div`
    margin: 10px 0;
  `,
  CaptionCopyContent: styled.div`
    font-family: ${Theme.fonts.families.AvenirBook};
    width: 90%;
    margin: 0 auto;
  `
};
