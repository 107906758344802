import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SC from './style';

const Carousel = (props) => {
  const { contents, interval = 3000 } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const latestContentLength = useRef();
  const timer = useRef();

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setCurrentIndex((prev) =>
        prev === latestContentLength.current - 1 ? 0 : prev + 1
      );
    }, interval);
    return () => {
      clearInterval(timer.current);
    };
  }, [interval]);

  useEffect(() => {
    latestContentLength.current = contents.length;
  }, [contents]);

  const onSlideChange = (direction) => {
    if (direction === 'right') {
      setCurrentIndex((prev) => (prev === contents.length - 1 ? 0 : prev + 1));
    } else if (direction === 'left') {
      setCurrentIndex((prev) => (prev === 0 ? contents.length - 1 : prev - 1));
    }
  };

  return contents && contents.length ? (
    <SC.Grid>
      <SC.ImgContainer>
        {contents.map((img, i) => (
          <SC.A
            key={i}
            index={i}
            href={img.link}
            target={img.popup ? '_blank' : '_self'}
            currentIndex={currentIndex}
          >
            <SC.Img src={img.imgSrc} alt={img.alt} />
          </SC.A>
        ))}
      </SC.ImgContainer>
      <SC.LeftArrow
        onClick={() => {
          onSlideChange('left');
        }}
      />
      <SC.RightArrow
        onClick={() => {
          onSlideChange('right');
        }}
      />
    </SC.Grid>
  ) : null;
};

Carousel.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      popup: PropTypes.bool,
      link: PropTypes.string,
      imgSrc: PropTypes.string,
      alt: PropTypes.string
    })
  ),
  interval: PropTypes.number
};

export default Carousel;
